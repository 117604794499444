<template>
  <main-container>
    <div class="banner">
      <div>
        <p>中师华图</p>
        <p>好老师&ensp;好课程&ensp;好服务</p>
      </div>
    </div>
    <div class="kernel">
      <section>
        <p>诚信为根</p>
        <p>质量为本</p>
        <p>持续奋斗</p>
        <p>开拓创新</p>
      </section>
    </div>
    <section class="education">
      <p class="title">
        <span>专注于教育文化产业</span>
      </p>
      <p class="subhead">致力于教师成长专业</p>
      <div class="business">
        <div>
          <img data-src="https://ht-teach.oss-cn-beijing.aliyuncs.com/frontend/jiaoyan/business1.png" alt="" />
          <p>培训基地</p>
        </div>
        <div>
          <img data-src="https://ht-teach.oss-cn-beijing.aliyuncs.com/frontend/jiaoyan/business2.png" alt="" />
          <p>课程培训</p>
        </div>
        <div>
          <img data-src="https://ht-teach.oss-cn-beijing.aliyuncs.com/frontend/jiaoyan/business3.png" alt="" />
          <p>专业教研</p>
        </div>
        <div>
          <img data-src="https://ht-teach.oss-cn-beijing.aliyuncs.com/frontend/jiaoyan/business4.png" alt="" />
          <p>尊享服务</p>
        </div>
      </div>
    </section>
    <img data-src="https://ht-teach.oss-cn-beijing.aliyuncs.com/frontend/jiaoyan/company.png" alt="" class="company" />
    <div class="aptitude">
      <section>
        <p class="zj1">培训资质</p>
        <p class="zj2">聚焦职业院校教师素质提高</p>
        <p class="zj3">职业院校教师素质提高计划项目任务承担资质</p>
        <img data-src="https://ht-teach.oss-cn-beijing.aliyuncs.com/frontend/jiaoyan/vocational.png" alt="" />
      </section>
    </div>
  </main-container>
</template>
<script>
export default {
  name: 'home',
  components: {},
  mounted() {
    var imgs = document.querySelectorAll('main img')

    function getTop(e) {
      var T = e.offsetTop
      while ((e = e.offsetParent)) {
        T += e.offsetTop
      }
      return T
    }

    function lazyLoad(imgs) {
      var H = document.documentElement.clientHeight //获取可视区域高度
      var S = document.documentElement.scrollTop || document.body.scrollTop
      for (var i = 0; i < imgs.length; i++) {
        if (H + S > getTop(imgs[i])) {
          imgs[i].src = imgs[i].getAttribute('data-src')
        }
      }
    }

    window.onload = window.onscroll = function () {
      lazyLoad(imgs)
    }
  }
}
</script>
<style scoped lang="less">
.banner {
  min-width: 1200px;
  height: 582px;
  background: url('https://ht-teach.oss-cn-beijing.aliyuncs.com/frontend/jiaoyan/home_bg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  > div {
    margin-top: 150px;
    p {
      font-size: 58px;
      color: #ffffff;
      line-height: 108px;
      text-shadow: 0px 9px 8px rgba(45, 46, 66, 0.3);
      text-align: center;
    }
  }
}
.kernel {
  padding: 60px 0;
  background: #fff;
  section {
    display: flex;
    justify-content: space-between;
  }
  p {
    width: 285px;
    height: 112px;
    background: #a30014;
    border-radius: 12px;
    font-size: 40px;
    color: #ffffff;
    line-height: 112px;
    text-align: center;
  }
}
.education {
  padding: 60px 0 91px;
  .title {
    text-align: center;
    span {
      font-size: 44px;
      color: #a30014;
      line-height: 60px;
      font-weight: bold;
      position: relative;
      &::before {
        content: '';
        width: 29px;
        height: 26px;
        position: absolute;
        background: url(../../assets/image/home/icon1.png) no-repeat 100% 100%;
        background-size: 100% 100%;
        left: -53px;
        top: 17px;
      }
      &::after {
        content: '';
        width: 29px;
        height: 26px;
        position: absolute;
        background: url(../../assets/image/home/icon1.png) no-repeat 100% 100%;
        background-size: 100% 100%;
        right: -53px;
        top: 17px;
        transform: rotate(180deg);
      }
    }
  }
  .subhead {
    text-align: center;
    font-size: 32px;
    color: #303133;
    line-height: 52px;
    margin: 20px 0 70px;
  }
  .business {
    display: flex;
    justify-content: space-between;
    > div {
      img {
        width: 285px;
        height: 198px;
        vertical-align: top;
      }
      p {
        width: 285px;
        height: 66px;
        background: #fff;
        font-weight: 500;
        font-size: 22px;
        color: #303133;
        line-height: 66px;
        text-align: center;
      }
    }
  }
}
.company {
  width: 100%;
  min-width: 1200px;
  height: 584px;
  display: block;
}
.aptitude {
  height: 624px;
  background: #fff;
  background: url(https://ht-teach.oss-cn-beijing.aliyuncs.com/frontend/jiaoyan/vocational_bg.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
  section {
    background: transparent;
    height: 100%;
    overflow: hidden;
    > img {
      width: 521px;
      height: 532px;
      position: absolute;
      top: 50px;
      right: 722px;
    }
    .zj1 {
      margin-top: 141px;
      font-weight: bold;
      font-size: 48px;
      color: #a30014;
      line-height: 65px;
    }
    .zj2 {
      margin-top: 32px;
      font-weight: 500;
      font-size: 28px;
      color: #a30014;
      line-height: 38px;
    }
    .zj3 {
      margin-top: 16px;
      font-size: 20px;
      color: #303133;
      line-height: 27px;
    }
  }
}
</style>
