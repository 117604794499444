<template>
  <main-container>
    <div class="banner">
      <div>
        <p>以科技推动教育数字化</p>
        <p>用更优质的产品与服务成就客户</p>
        <p>好老师&ensp;好课程&ensp;好服务</p>
      </div>
    </div>
    <div class="bg-white">
      <section class="education">
        <p class="title">
          <span>培训项目</span>
        </p>
        <p class="subhead">搭建一个专业实用的师资成长平台</p>
        <div class="business">
          <div>
            <img src="../../assets/image/programs/px1.png" alt="" />
            <p>岗位成长进阶</p>
          </div>
          <div>
            <img src="../../assets/image/programs/px2.png" alt="" />
            <p>核心通识素养</p>
          </div>
          <div>
            <img src="../../assets/image/programs/px3.png" alt="" />
            <p>职教重点专题</p>
          </div>
          <div>
            <img src="../../assets/image/programs/px4.png" alt="" />
            <p>重点专业建设</p>
          </div>
        </div>
      </section>
    </div>
    <section class="education policy">
      <p class="title">
        <span>政策现状</span>
      </p>
      <p class="otheSubhead">2020年9月   教育部等九部门印发《职业教育提质培优行动计划(2020—2023年)》</p>
      <p class="otheSubhead">2021年4月   加快构建现代职业教育体系，培养更多高素质技术技能人才能工巧匠大国工匠</p>
      <p class="otheSubhead">2021年9月   “十四五”职业院校教师素质提高计划启动</p>
      <p class="otheSubhead">2021年10月   中共中央办公厅 国务院办公厅印发《关于推动现代职业教育高质量发展的意见》</p>
      <p class="otheSubhead">2022年5月   《新职业教育法》实施</p>
      <div class="zcxz">
        <img src="../../assets/image/programs/xz1.png" alt="" />
        <img src="../../assets/image/programs/xz2.png" alt="" />
        <img src="../../assets/image/programs/xz3.png" alt="" />
      </div>
    </section>
    <div class="aptitude">
      <section>
        <p class="px">一体化人才服务方案</p>
        <p class="px">一站式学习提升服务</p>
        <p class="px">多层面多对象个性化</p>
        <p class="px">打造华图教师培训模式</p>
      </section>
    </div>
  </main-container>
</template>
<script>
export default {
  name: 'home',
  components: {}
}
</script>
<style scoped lang="less">
.banner {
  min-width: 1200px;
  height: 582px;
  background: url('https://ht-teach.oss-cn-beijing.aliyuncs.com/frontend/jiaoyan/course_bg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  > div {
    margin-top: 150px;
    p {
      font-size: 58px;
      color: #ffffff;
      line-height: 108px;
      text-shadow: 0px 9px 8px rgba(45, 46, 66, 0.3);
      text-align: center;
    }
  }
}
.education {
  background: #fff;
  padding: 60px 0 90px;
  &.policy {
    background: transparent;
    padding: 60px 0 60px;
  }
  .title {
    text-align: center;
    span {
      font-size: 44px;
      color: #a30014;
      line-height: 60px;
      font-weight: bold;
      position: relative;
      &::before {
        content: '';
        width: 29px;
        height: 26px;
        position: absolute;
        background: url(../../assets/image/home/icon1.png) no-repeat 100% 100%;
        background-size: 100% 100%;
        left: -53px;
        top: 17px;
      }
      &::after {
        content: '';
        width: 29px;
        height: 26px;
        position: absolute;
        background: url(../../assets/image/home/icon1.png) no-repeat 100% 100%;
        background-size: 100% 100%;
        right: -53px;
        top: 17px;
        transform: rotate(180deg);
      }
    }
  }
  .subhead {
    text-align: center;
    font-size: 32px;
    color: #303133;
    line-height: 52px;
    margin: 20px 0 70px;
  }
  .business {
    display: flex;
    justify-content: space-between;
    > div {
      img {
        width: 285px;
        height: 198px;
        vertical-align: top;
      }
      p {
        width: 285px;
        height: 66px;
        background: #f7f9fa;
        font-weight: 500;
        font-size: 22px;
        color: #303133;
        line-height: 66px;
        text-align: center;
      }
    }
  }
  .zcxz {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    img {
      width: 394px;
      height: 394px;
    }
  }
  .otheSubhead {
    font-weight: 400;
    font-size: 28px;
    color: #303133;
    line-height: 44px;
    text-align: center;
    overflow: hidden;
    &:nth-child(2) {
      margin-top: 28px;
    }
  }
}
.aptitude {
  height: 624px;
  background: #fff;
  background: url(https://ht-teach.oss-cn-beijing.aliyuncs.com/frontend/jiaoyan/vocational_bg.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
  section {
    background: transparent;
    height: 100%;
    overflow: hidden;
    > img {
      width: 521px;
      height: 532px;
      position: absolute;
      top: 50px;
      right: 722px;
    }
    .px {
      font-weight: 500;
      font-size: 28px;
      color: #a30014;
      line-height: 56px;
    }
    .px:first-child {
      margin-top: 70px;
    }
  }
}
</style>
