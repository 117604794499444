import Layout from '@/Layout/index.vue'

export default [
  {
    path: '/mycourse',
    name: 'mycourse',
    redirect: '/mycourse/detail',
    component: Layout,
    children: [
      {
        path: '/mycourse/detail/:id',
        name: 'mycourseDetail',
        component: () => import('@/views/course/detail.vue')
      }
    ]
  },
  {
    path: '/videoPlaying',
    name: 'videoPlaying',
    component: () => import('@/components/live'),
    meta: {
      title: '视频观看'
    }
  }
]
