import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    roleList: []
  },
  getters: {},
  mutations: {
    setRoleList(state, payload) {
      state.roleList = payload
    }
  },
  actions: {},
  modules: {}
})
