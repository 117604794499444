import Layout from '@/Layout/index.vue'

import HomePage from '@/views/home/home.vue'
import TrainingCourse from '@/views/home/trainingCourse.vue'
import TrainingPrograms from '@/views/home/trainingPrograms.vue'
import AboutUs from '@/views/home/aboutUs.vue'
export default [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'HomePage',
        component: HomePage
      },
      {
        path: '/trainingCourse',
        name: 'trainingCourse',
        component: TrainingCourse
      },
      {
        path: '/trainingPrograms',
        name: 'trainingPrograms',
        component: TrainingPrograms
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: AboutUs
      }
    ]
  }
]
