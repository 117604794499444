import Vue from 'vue'
import VueRouter from 'vue-router'

import common from './modules/common' //公共banner页
import project from './modules/project' //项目页
import course from './modules/course' //课程

Vue.use(VueRouter)

const routes = [...common, ...project, ...course]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
