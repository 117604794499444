<template>
  <main-container>
    <div class="banner">
      <div>
        <p>教师是教育工作的中坚力量</p>
        <p>有高质量的教师, 才会有高质量的教育</p>
        <p>好老师&ensp;好课程&ensp;好服务</p>
      </div>
    </div>
    <div class="bg-white">
      <section class="education">
        <p class="title">
          <span>培训对象</span>
        </p>
        <p class="subhead">助推教师队伍建设</p>
        <div class="business">
          <div>
            <img src="../../assets/image/course/object1.png" alt="" />
            <p>学科教师</p>
          </div>
          <div>
            <img src="../../assets/image/course/object2.png" alt="" />
            <p>校（园）长</p>
          </div>
          <div>
            <img src="../../assets/image/course/object3.png" alt="" />
            <p>班主任</p>
          </div>
          <div>
            <img src="../../assets/image/course/object4.png" alt="" />
            <p>管理干部</p>
          </div>
          <div>
            <img src="../../assets/image/course/object5.png" alt="" />
            <p>培训者</p>
          </div>
        </div>
      </section>
    </div>

    <section class="education">
      <p class="title">
        <span>优质课程资源</span>
      </p>
      <p class="subhead resource">
        组建由院校教授、技术能手、职教专家、行业企业高水平人员等组成的培训专家队伍
        开发一批教师培训优势特色专业和优质课程资源
      </p>
      <div class="businesSresource">
        <div>
          <img src="../../assets/image/course/zy1.png" alt="" />
          <p>坚定不移全面从严治党，深入推进新时代党的建设新的伟大工程</p>
        </div>
        <div>
          <img src="../../assets/image/course/zy2.png" alt="" />
          <p>夯实百年大党治党治国的制度根基——《中国共产党党内法规体系》解读</p>
        </div>
        <div>
          <img src="../../assets/image/course/zy3.png" alt="" />
          <p>认真学习党的二十大精神，开辟马克思主义中国化时代化新境界</p>
        </div>
        <div>
          <img src="../../assets/image/course/zy4.png" alt="" />
          <p>深入学习贯彻党的二十大建设，书写全面建设社会主义现代化国家崭新篇章</p>
        </div>
        <div>
          <img src="../../assets/image/course/zy5.png" alt="" />
          <p>职业教育课堂教学组织实施方法应用</p>
        </div>
        <div>
          <img src="../../assets/image/course/zy6.png" alt="" />
          <p>高职院校科研管理人员能力提升培训班</p>
        </div>
        <div>
          <img src="../../assets/image/course/zy7.png" alt="" />
          <p>专业层面的课程思政建设与课程教学模式创新与实施</p>
        </div>
        <div>
          <img src="../../assets/image/course/zy8.png" alt="" />
          <p>双高”背景下高水平专业群及特色品牌专业建设与实施</p>
        </div>
      </div>
    </section>
    <div class="aptitude">
      <section>
        <p>
          <span>课程体系</span>
          <span>完善、全面, 内容专业、实用</span>
        </p>
        <p>
          <span>教学形式</span>
          <span>录播、直播、课件资料</span>
        </p>
        <p>
          <span>学习方式</span>
          <span>线上学习、线下培训结合</span>
        </p>
        <p>
          <span>培训证书</span>
          <span>可作为继续教育学习凭证, 计入教师继续教育学时</span>
        </p>
      </section>
    </div>
  </main-container>
</template>
<script>
export default {
  name: 'home',
  components: {}
}
</script>
<style scoped lang="less">
.banner {
  min-width: 1200px;
  height: 582px;
  background: url('https://ht-teach.oss-cn-beijing.aliyuncs.com/frontend/jiaoyan/course_bg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  > div {
    margin-top: 150px;
    p {
      font-size: 58px;
      color: #ffffff;
      line-height: 108px;
      text-shadow: 0px 9px 8px rgba(45, 46, 66, 0.3);
      text-align: center;
    }
  }
}
.bg-white .education {
  background: #fff;
  padding: 60px 0 90px;
}
.education {
  padding: 60px 0 80px;
  .title {
    text-align: center;
    span {
      font-size: 44px;
      color: #a30014;
      line-height: 60px;
      font-weight: bold;
      position: relative;
      &::before {
        content: '';
        width: 29px;
        height: 26px;
        position: absolute;
        background: url(../../assets/image/home/icon1.png) no-repeat 100% 100%;
        background-size: 100% 100%;
        left: -53px;
        top: 17px;
      }
      &::after {
        content: '';
        width: 29px;
        height: 26px;
        position: absolute;
        background: url(../../assets/image/home/icon1.png) no-repeat 100% 100%;
        background-size: 100% 100%;
        right: -53px;
        top: 17px;
        transform: rotate(180deg);
      }
    }
  }
  .subhead {
    text-align: center;
    font-size: 32px;
    color: #303133;
    line-height: 52px;
    margin: 20px 0 70px;
  }
  .resource {
    width: 1020px;
    font-size: 28px;
    line-height: 44px;
    margin: 28px auto 60px;
  }
  .business {
    display: flex;
    justify-content: space-between;
    > div {
      img {
        width: 224px;
        height: 156px;
        vertical-align: top;
      }
      p {
        width: 224px;
        height: 68px;
        background: #f7f9fa;
        font-weight: 500;
        font-size: 22px;
        color: #303133;
        line-height: 68px;
        text-align: center;
      }
    }
  }
  .businesSresource {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
      img {
        width: 285px;
        height: 198px;
        vertical-align: top;
      }
      p {
        width: 285px;
        height: 44px;
        background: #fff;
        font-weight: 500;
        font-size: 14px;
        color: #303133;
        line-height: 44px;
        text-align: center;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0 13px;
      }
    }
    > div:nth-child(n + 5) {
      margin-top: 40px;
    }
  }
}
.aptitude {
  height: 624px;
  background: #fff;
  background: url(https://ht-teach.oss-cn-beijing.aliyuncs.com/frontend/jiaoyan/vocational_bg.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
  section {
    background: transparent;
    height: 100%;
    overflow: hidden;
    p {
      margin-bottom: 32px;
      max-height: 76px;
      display: flex;
      span:first-child {
        font-weight: 500;
        font-size: 28px;
        color: #a30014;
        line-height: 38px;
      }
      span:last-child {
        display: inline-block;
        margin-left: 19px;
        max-width: 389px;
        font-weight: 400;
        font-size: 28px;
        line-height: 38px;
      }
      &:first-child {
        margin-top: 79px;
      }
    }
  }
}
</style>
