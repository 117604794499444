import axios from 'axios'
import { Message } from 'element-ui'
const querystring = require('querystring')
import router from '@/router'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 600000
})
let open = false
// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    let satoken = localStorage.getItem('satoken')
    if (satoken) {
      config.headers.satoken = satoken
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    response = response.data
    // console.log(response)
    if (response.code == 307) {
      localStorage.removeItem('satoken')
      router.push('/').catch(() => {})
      setTimeout(() => {
        location.reload()
      }, 1000)
      return Promise.reject()
    }

    return response
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    if (axios.isCancel(error)) {
      Message.error('请求超时')
    } else {
      if (error.response.data && error.response.data.message) {
        Message.error(error.response.data.message)
      } else {
        Message.error('接口请求异常，请检查网络或服务器是否异常')
      }
    }
    return Promise.reject(error)
  }
)

export default request

/**
 * 发送 post 请求
 *
 * @param {string} url 请求 url
 * @param {Object} data 发送的数据
 * @return {Promise}
 */
export function post(url, data = {}) {
  return request.post(url, querystring.stringify(data))
}

export async function postWithJson(url, data = {}) {
  return request.post(url, data)
}

export async function postFile(url, data = {}) {
  return request({
    method: 'post',
    url: url,
    data: data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * 发送post请求接受blob
 * @param url 请求api
 * @param data 请求参数
 * @returns Promise
 */
export function axiosPostBlob(url, data = {}) {
  return request({
    method: 'post',
    url: url,
    data: data,
    responseType: 'blob'
  })
}

/**
 * 发送post请求接受blob
 * @param url 请求api
 * @param data 请求参数
 * @returns Promise
 */
export function axiosGetBlob(url, data = {}) {
  return request({
    method: 'get',
    url: url,
    params: data,
    responseType: 'blob'
  })
}

/**
 * post form参数请求
 *
 * @param {string} url 请求 url
 * @param {Object} data 发送的数据
 * @return {Promise}
 */
export function form(url, data = {}) {
  return request.post(url, querystring.stringify(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * 发送 delete 请求
 *
 * @param {string} url 请求 url
 * @param {Object} data 发送的数据
 * @return {Promise}
 */
export function del(url, data = {}) {
  return request.delete(url, {
    params: data
  })
}

/**
 * 发送 get 请求
 *
 * @param {string} url 请求 url
 * @param {Object} data 发送的数据
 * @return {Promise}
 */
export function get(url, data = {}) {
  return request.get(url, {
    params: data
  })
}

/**
 * 发送 update 请求
 *
 * @param {string} url 请求 url
 * @param {Object} data 发送的数据
 * @return {Promise}
 */
export function put(url, data = {}) {
  return request.put(url, data)
}
