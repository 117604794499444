<template>
  <div class="home">
    <Head />
    <router-view></router-view>
    <Foot />
  </div>
</template>
<script>
import Head from './header_nav.vue'
import Foot from './footer_nav.vue'
export default {
  name: 'home',
  components: {
    Head,
    Foot
  }
}
</script>
<style scoped>
.home {
  overflow: hidden;
}
.home b {
  color: red;
}
</style>
