import Layout from '@/Layout/index.vue'

export default [
  {
    path: '/project',
    name: 'project',
    redirect: '/project/index',
    component: Layout,
    children: [
      {
        path: '/project/index',
        name: 'projectIndex',
        component: () => import('@/views/project')
      },
      {
        path: '/project/myClass',
        name: 'myClass',
        component: () => import('@/views/project/myClass')
      },
      {
        path: '/project/myCertificate',
        name: 'myCertificate',
        component: () => import('@/views/project/components/MyCertificate')
      },
      {
        path: '/projectIntroduce',
        name: 'projectIntroduce',
        component: () => import('@/views/project/projectIntroduce')
      }
    ]
  }
]
