<template>
  <header>
    <div class="header_box">
      <a href="/" class="logo" style="float: left; margin-top: 31px">
        <img src="@/assets/image/logo.png" alt="" />
      </a>
      <ul>
        <router-link tag="li" to="/" active-class="active">首页</router-link>
        <router-link tag="li" to="/trainingCourse" active-class="active">培训课程</router-link>
        <router-link tag="li" to="/trainingPrograms" active-class="active">培训项目</router-link>
        <router-link tag="li" to="/aboutUs" active-class="active">关于我们</router-link>
      </ul>
      <div class="login_userInfo">
        <span class="el-dropdown-link" v-if="!satoken" @click="loginVisible = true">登录</span>
        <el-dropdown @command="handleCommand" placement="bottom" v-else>
          <span class="el-dropdown-link">个人中心</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="myProject">我的项目</el-dropdown-item>
            <el-dropdown-item command="changePassword">修改密码</el-dropdown-item>
            <el-dropdown-item command="logOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog :visible.sync="loginVisible" width="450px" :close-on-click-modal="false">
      <p class="title">登录</p>
      <el-form :model="form">
        <el-form-item>
          <el-input placeholder="账号/身份证号/手机号" v-model="form.account" />
          <p class="errorTitle" v-if="showError">用户名或密码错误！</p>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="密码" type="password" v-model="form.password" @keyup.enter.native="login" />
        </el-form-item>
      </el-form>
      <button class="login" :style="{ opacity: `${form.account && form.password ? 1 : 0.5}` }" @click="login">
        登录
      </button>
    </el-dialog>
  </header>
</template>
<script>
import { get } from '@/utils/request'
export default {
  name: 'header_nav',
  data() {
    return {
      loginVisible: false,
      form: {
        account: '',
        password: ''
      },
      showError: false,
      satoken: localStorage.getItem('satoken') || ''
    }
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case 'myProject':
          this.$router.push({ path: '/project/index', query: { activeIndex: 0 } }).catch((err) => err)
          break
        case 'changePassword':
          this.$router.push({ path: '/project/index', query: { activeIndex: 1 } }).catch((err) => err)
          break
        case 'logOut':
          localStorage.removeItem('satoken')
          this.satoken = ''
          this.$router.push({ path: '/' }).catch((err) => err)
      }
    },
    login() {
      get('/training/user/login', this.form)
        .then((res) => {
          if (res.code == 200) {
            localStorage.setItem('satoken', res.data.tokenInfo.tokenValue)
            localStorage.setItem('roleList', JSON.stringify(res.data.roleList))
            localStorage.setItem('userName', res.data.user.name)
            this.$store.commit('setRoleList', res.data.roleList)
            this.satoken = res.data.tokenInfo.tokenValue
            this.loginVisible = false
            this.showError = false
          } else {
            this.showError = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
<style>
.el-dropdown-link {
  cursor: pointer;
  color: #303133;
  font-size: 20px;
  line-height: 28px;
}
.el-icon-arrow-down {
  font-size: 14px;
  color: #666666;
}
.el-dropdown-menu__item:hover {
  background: rgba(111, 120, 140, 0.2) !important;
  color: #a30014 !important;
}
</style>

<style scoped lang="less">
header {
  width: 100%;
  height: 98px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  .header_box {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .logo {
      margin-right: 100px;
    }
    .logo img {
      width: 146px;
      height: 37px;
      float: left;
    }
    ul {
      height: 33px;
      float: left;
      line-height: 33px;
      margin-top: 33px;
      li {
        float: left;
        margin-right: 68px;
        text-align: center;
        color: #303133;
        cursor: pointer;
        font-weight: 500;
        font-size: 24px;
        &:hover {
          color: #a30014;
        }
      }
      .router-link-exact-active {
        color: #a30014;
      }
    }
    .login_userInfo {
      float: right;
      margin-top: 35px;
      font-size: 20px;
    }
  }
}
.el-dialog {
  .title {
    font-weight: 500;
    font-size: 28px;
    color: #303133;
    line-height: 40px;
    text-align: center;
    margin-bottom: 49px;
    font-family: PingFangSC, PingFang SC;
  }
  .login {
    width: 100%;
    height: 44px;
    background: #a30014;
    border-radius: 8px;
    // opacity: 0.5;
    border: none;
    font-size: 16px;
    color: #fff;
    margin-top: 93px;
  }
  .el-form {
    height: 120px;
  }
}

:deep(.el-input__inner) {
  height: 44px;
  &:focus {
    border-color: #6f788c;
  }
}

:deep(.el-dialog) {
  border-radius: 8px;
}

:deep(.el-dialog__header) {
  padding: 0;
}
:deep(.el-dialog__body) {
  padding: 48px 66px 81px 64px;
}
.errorTitle {
  color: #ff4e45;
  font-size: 12px;
  position: absolute;
  line-height: 12px;
  margin-top: 4px;
}
</style>
