<template>
  <main-container>
    <div class="banner"></div>
    <div class="bg-white">
      <section class="intro">
        <img src="https://ht-teach.oss-cn-beijing.aliyuncs.com/frontend/jiaoyan/aboutUs_img.png" alt="" />
        <div>
          <p class="title">中师华图</p>
          <p class="content">
            北京中师华图文化发展有限公司成立于2015年，是专业从事教师资格证、教师招聘、特岗教师、在职教师培训等考试培训业务的平台，简称“中师华图”。自成立以来，中师华图坚持“以客户为中心”的经营理念，在全国各地市均设立了学习中心，拥有近2000名员工，有完善的课前、课中、课后服务体系，每年服务近100万人次的线上线下学员，所推出的丰富课程和多样教学形式在广大学员中有口皆碑、经久不衰，树立了职业教育培训的行业标准。
          </p>
          <p class="title contactway">联系方式</p>
          <p class="content">邮箱：yangjie04@zhongshi.com.cn</p>
          <p class="content">联系人：杨老师 13810710868</p>
        </div>
      </section>
    </div>
  </main-container>
</template>
<script>
export default {
  name: 'home',
  components: {}
}
</script>
<style scoped lang="less">
.banner {
  min-width: 1200px;
  height: 582px;
  background: url('https://ht-teach.oss-cn-beijing.aliyuncs.com/frontend/jiaoyan/aboutUs_bg.png') no-repeat;
  background-size: 100% 100%;
}
.intro {
  background: #fff;
  display: flex;
  padding: 70px 0 231px;
  img {
    width: 318px;
    height: 502px;
  }
  > div {
    width: 842px;
    margin-left: 40px;
    .title {
      font-weight: 500;
      font-size: 36px;
      color: #a30014;
      line-height: 50px;
      margin-top: 8px;
    }
    .title + .content {
      margin-top: 16px;
    }
    .content {
      font-size: 22px;
      color: #303133;
      line-height: 48px;
    }
    .contactway {
      margin-top: 104px;
    }
  }
}
</style>
