import router from '.'

//模块加载失败报错时页面停顿无反应 bug，刷新页面
router.onError((err) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadError = pattern.test(err.message)
  if (isChunkLoadError) {
    location.reload()
  }
})
